






















































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ExpansionPanel, Table, TableRow } from '@/types/cms/components';

/**
 * MaterialsExpansionPanelFormDialog component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class MaterialsExpansionPanelFormDialog extends Vue {
    // Prop of type boolean that defined whether the dialog should be shown or not
    @Prop(Boolean) readonly showDialog: boolean | undefined;

    // Prop that holds the materialsExpansionPanel data
    @Prop({type: Object as () => ExpansionPanel<Table>}) readonly editedMaterialsExpansionPanelItem: ExpansionPanel<Table> | undefined;

    // Prop that holds the id of the edited materialsExpansionPanel
    @Prop(Number) readonly editedMaterialsExpansionPanelIndex: number | undefined;

    // An crud string that defines the mode of the dialog
    @Prop(String) readonly dialogMode: string | undefined;

    // Boolean that defines wheter the form data is valid or not
    private formValid = false;

    private tableHeaderString = '';

    /**
     * @returns the title for the form
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get formTitle(): string {
        return (this.dialogMode) ? this.$t('form.titles.' + this.dialogMode + 'MaterialsExpansionPanel').toString() : '';
    }

    /**
     * Resets the form validation
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private resetFormValidation() {
        if (this.$refs.form) {
            (this.$refs.form as Vue & { resetValidation: () => void }).resetValidation();
        }
    }

    /**
     * Function will be executed on component load
     * Calls tableHeaderObjectToString
     */
    private created() {
        this.tableHeaderObjectToString();
    }

    /**
     * Converts object to string seperated by pipe
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Watch('editedMaterialsExpansionPanelItem.content.headers')
    private tableHeaderObjectToString() {
        this.tableHeaderString =  this.editedMaterialsExpansionPanelItem?.content.headers.map(h => h.content).join('|') || '';
    }

    /**
     * Parses tableHeaderString to object
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private tableHeaderStringToObject() {
        if (!this.editedMaterialsExpansionPanelItem) return;

        this.editedMaterialsExpansionPanelItem.content.headers = [];

        const tableHeaderArray = this.tableHeaderString.split('|');
        if (this.tableHeaderString === '') return;

        tableHeaderArray.forEach((th, i) => {
            if (this.editedMaterialsExpansionPanelItem) {
                this.editedMaterialsExpansionPanelItem.content.headers.push({
                    id: i,
                    content: th
                });
            }
        });

        this.editedMaterialsExpansionPanelItem.content.data.forEach(row => {
            while (row.length > tableHeaderArray.length) {
                row.pop();
            }

            while (row.length < tableHeaderArray.length) {
                if (!this.editedMaterialsExpansionPanelItem) return;

                let maxId = -1;
                this.editedMaterialsExpansionPanelItem.content.data.forEach(r => {
                    r.forEach(obj => {
                        if (obj.id > maxId) maxId = obj.id;
                    });
                });

                row.push({
                    id: maxId +1,
                    content: ''
                });
            }
        });
    }

    /**
     * Saves/Updates or deletes the materialsExpansionPanel
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private saveMaterialsExpansionPanel() {
        if (this.editedMaterialsExpansionPanelItem) {
            this.resetFormValidation();
            this.$emit('materialsExpansionPanelSaved', this.dialogMode, this.editedMaterialsExpansionPanelIndex, this.editedMaterialsExpansionPanelItem);
        }
    }

    /**
     * Adds new row to table
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private addRow() {
        if (!this.editedMaterialsExpansionPanelItem) return;

        const tableRow: TableRow[] = [];

        for (let i = 0; i < this.editedMaterialsExpansionPanelItem.content.headers.length; i++) {
            tableRow.push({
                id: i,
                content: '-'
            });
        }
        this.editedMaterialsExpansionPanelItem.content.data.push(tableRow);
    }

    /**
     * Deletes given row
     * 
     * @row row
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private deleteRow(row: TableRow) {
        if (!this.editedMaterialsExpansionPanelItem) return;

        const rowIndex = this.editedMaterialsExpansionPanelItem.content.data.findIndex(tr => tr.length > 0 && tr[0].id === row.id);
        if (rowIndex === -1) return;

        this.editedMaterialsExpansionPanelItem.content.data.splice(rowIndex, 1);
    }

    /**
     * Closes the dialog
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private closeDialog() {
        this.resetFormValidation();
        this.$emit('dialogClosed');
    }
}
